// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { environment } from '@env/environment';
import { AppModule } from '@app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConfig } from '@app/app.config';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
